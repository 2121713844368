export default () => {
  const heroVerticalPadding = '3rem'

  return {
    iframe: {
      margin: '0 auto',
      display: 'flex',
      width: '100%',
      height: '100vh',
      border: 'none',
    },

    headerText: {
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      padding: `${heroVerticalPadding} 0`,
    },
  }
}
